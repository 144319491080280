import { useState, useEffect, useRef } from "react";

// Custom Hook para Lazy Loading
export const useLazyLoad = (imageSrc, rootMargin = "200px") => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const node = containerRef.current;

    const handleImageLoad = () => {
      setIsLoaded(true); // Cambiar el estado solo cuando la imagen se haya cargado completamente
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoaded) {
          const img = new Image();
          img.src = imageSrc;
          img.onload = handleImageLoad; // Cuando la imagen se haya cargado, actualizar el estado
        }
      },
      { rootMargin }
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [imageSrc, rootMargin, isLoaded]);

  return { isLoaded, containerRef };
};
