import tw from "twin.macro";
import styled from "styled-components";

// Contenedor principal con fondo fijo
export const MainContainer = styled.div`
  ${tw`h-[90vh] relative -mx-8 -mt-16 pt-16`}
  background-color: #330066; // Color de fondo fijo
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 1368px) {
    ${tw`h-[70vh] relative -mx-8 -mt-8`}
  }
  @media (max-width: 1280px) {
    ${tw`h-[50vh] relative -mx-8 -mt-8`}
  }
  @media (max-width: 820px) {
    ${tw`h-[45vh] relative -mx-8 -mt-8`}
  }
`;