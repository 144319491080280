import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "layouts/MainLayout";
import MainBackGround from "components/features/BackgroundAsImage.js";
import Expertise from "components/features/Expertise.js";
import Team from "components/features/Team.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Services from "components/features/Services.js";
import ContactUsFormFull from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Clients from "components/features/ClientsSection";

export default function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    // Obtener los parámetros de la URL
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Layout>
      <MainBackGround />
      <Clients />
      <Services />
      <Portfolio />
      <Expertise />
      <Team />
      <ContactUsFormFull />
    </Layout>
  );
}
