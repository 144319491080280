import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logoHanoIT.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  justify-between items-center
  max-w-full mx-auto
`;

export const NavLinks = tw.div`inline-block`;

// Estilos para NavLink, que sí soporta activeClassName
export const StyledNavLink = styled(NavLink)`
  ${tw`text-white text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}

  &.active {
    ${tw`border-primary-500`}
    color: #b52dd7;
  }
`;

// Elimina activeClassName de HashLink, ya que no lo soporta correctamente
export const StyledHashLink = styled(HashLink)`
  ${tw`text-white text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}

  &:hover, &:focus {
    color: #b52dd7;
    border-color: #b52dd7;
  }
`;

// Actualizamos PrimaryLink para que use StyledNavLink
export const PrimaryLink = styled(StyledNavLink)`
  ${tw`lg:mx-0 px-8 py-3 rounded bg-primary-500 text-white
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0`}

  &:hover, &:focus {
    background-color: #b52dd7;
    color: white;
  }
`;

// Actualizamos LogoLink para que use StyledHashLink sin subrayado
export const LogoLink = styled(StyledHashLink)`
  ${tw`flex items-center text-white font-black border-b-0 text-2xl! mr-8 lg:mr-24 my-3`};

  &:hover, &:focus {
    ${tw`border-b-0 text-white`}
  }

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
  mr-0
`;

export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden w-full z-10 fixed top-0 left-0 right-0 flex items-center justify-center p-8 bg-white text-black`}

  border: 3px solid rgba(51, 0, 102, 0.5);

  ${NavLinks} {
    ${tw`flex flex-col items-center p-4`}

    a {
      ${tw`text-black text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent`}

      &:hover, &:focus {
        color: #b52dd7;
        border-color: #b52dd7;
      }

      &.active {
        color: #b52dd7;
        border-color: #b52dd7;
      }
    }
  }

  transform: translateY(0);
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <StyledNavLink to="/aboutus">About Us</StyledNavLink>
      <StyledHashLink smooth to="/#our-services">Our Services</StyledHashLink>
      <StyledHashLink smooth to="/#our-portfolio">Our Portfolio</StyledHashLink>
      <StyledHashLink smooth to="/#contact">Contact Us</StyledHashLink>
      <PrimaryLink to="/signup" tw="lg:ml-12!">Sign Up</PrimaryLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink smooth to="/#main">
      <img src={logo} alt="logo" />
      HanoIT
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "100%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* Generar puntos de quiebre dinámicos para el navbar */
const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
