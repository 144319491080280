import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainLandingPage from "./pages/LandingPage"
const AboutUs = lazy(() => import('./pages/AboutUs'));

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLandingPage />} />
        <Route
          path="/aboutus"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AboutUs />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}
