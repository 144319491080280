import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import geotwitterImg from "images/geotwitter-solution.jpg";
import NYCParcelsGif from "images/NYC_Parcels_Tax_Administration.gif";
import gisWaterNetGif from "images/automated_water_waste_gis_network.gif"
import gis_roof_measurement from "images/gis_roof_measurement.gif"
import gis_infrastructure from "images/gis_infrastructure.gif"


// Importar el hook personalizado
import { useLazyLoad } from "../hook/useLazyLoad.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingColumn = styled.div`
  ${tw`w-full mb-16`}
`;

const CardColumn = tw.div`w-full mb-[4rem]`;

const HeadingInfoContainer = tw.div`max-w-screen-xl text-center mx-auto`;
const HeadingTitle = styled(SectionHeading)`
  ${tw`mt-4 leading-tight`}
  align-items: center;
  justify-content: center;
`;

/* const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`; */

const Card = tw.div`mx-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  tw`w-full h-80 bg-center bg-no-repeat bg-contain rounded`,
  `@media (min-width: 768px) {
    background-size: cover;
  }`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;

const PaginationStyles = styled.div`
  .swiper-button-next {
    right: 0.4rem; /* Ajusta la posición horizontal de la flecha derecha */
    top: 25%;
  }

  .swiper-button-prev {
    left: 0.4rem; /* Ajusta la posición horizontal de la flecha izquierda */
    top: 25%;
  }
`;

const LazyLoadCardImage = ({ imageSrc }) => {
  const { isLoaded, containerRef } = useLazyLoad(imageSrc);

  return (
    <CardImage
      ref={containerRef}
      style={{
        backgroundImage: isLoaded ? `url(${imageSrc})` : "none",
        minHeight: '200px'
      }}
    />
  );
};

export default ({
  subheading = "Our Portfolio",
  headingHtmlComponent = (
    <>
      We've done some<span tw="ml-2 text-primary-500">GIS amazing projects.</span>
    </>
  ),
/*   linkText = "View all Projects",
  cardLinkText = "Read Case Study" */
}) => {
  const cards = [
    {
      imageSrc: NYCParcelsGif,
      company: "LiRo-Hill",
      type: "Parcels Management",
      title: "NYC Parcels And Tax Administration",
      durationText: "The Property Information Portal is a one stop shop for taxpayers to access their property card, manipulate data by selecting geographic boundaries, and view depictions of condominium, air and subterranean lots. NYC is one of the few places in the world that has implemented the 3D component, which is considered the next generation of digital tax mapping.",
      locationText: "New York City, NY"
    },
    {
      imageSrc: geotwitterImg,
      company: "Geomarketing",
      type: "Geospatial Intelligence",
      title: "Real-Time Spatial and Temporal Sentiment Analysis on Twitter",
      durationText: "A web service based on python retrieves real-time comments from Twitter and utilizes machine learning models to estimate and infer location based on the comment data. This addresses the issue of limited geographic data for sentiment analysis studies in specific regions and enables smooth integration with popular business intelligence tools like Tableau, Power BI, and Qlik.",
      locationText: "Bogotá, Colombia"
    },
    {
      imageSrc:gisWaterNetGif,
      company: "DIG",
      type: "Planning and Management for Utilities",
      title: "Design and Develop GIS Waste Water Solutions",
      durationText: "Our expertise in GIS web applications is exemplified through the development of DIG, a Waste Water System GIS Web App—a powerful yet user-friendly tool designed to streamline the creation and management of waste water infrastructure from the ground up. Built with ArcGIS Experience Builder and enhanced by custom Python geoprocessing tools, this app makes complex system design accessible to all, regardless of technical expertise.",
      locationText: "Boise, ID"
    },
    {
      imageSrc: gis_infrastructure,
      company: "Beexact",
      type: "Consultancy and Staffing",
      title: "Transforming Infrastructure with Hi-tech Solutions",
      durationText: "Our extensive experience has enhanced the use of the Esri ArcGIS platform within Beexact's operations. We collaborated on data mining projects, processing real-time IoT data for asset tracking and management. Additionally, we developed custom widgets, geoprocessing tools, mobile and web solutions to elevate user interaction while streamlining workflows, mapping analysis, and visualization.",
      locationText: "Netherlands, USA and Germany"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1573165231977-3f0e27806045?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      company: "Retail Scientifcs",
      type: "Location Intelligence",
      title: "Unlocking Geographic Insights with Advanced Visualization",
      durationText: "Our expert GIS team enhances decision-making through Location Intelligence Analytics (LIA). By leveraging ArcGIS technologies, we offer visualization tools that seamlessly integrate machine learning and deep learning insights into interactive dashboards. Utilizing Experience Builder and WebAppBuilder, our solutions enable effective visualization of insights, driving informed decision-making.",
      locationText: "San Diego, CA"
    },
    {
      imageSrc: gis_roof_measurement,
      company: "Xscope",
      type: "Consultancy and Staffing",
      title: "Precision Roof Measurement and Reporting Platform",
      durationText: "We offer premier GIS staffing and consulting services. Our expert team developed a platform that enables the creation of professional roof measurement reports tailored to specific business needs. The system facilitates budget preparation, precise measurements, and detailed annotations, providing comprehensive reports with professional drawings to simplify complex data into actionable insights.",
      locationText: "Coral Springs, FL"
    }
  ];

  return (
    <Container id="our-portfolio">
      <Content>
        <HeadingColumn>
          <HeadingInfoContainer>
            <Subheading>{subheading}</Subheading>
            <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
          </HeadingInfoContainer>
        </HeadingColumn>
        <PaginationStyles>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              320: { slidesPerView: 1 },
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            className="swiper-portfolio"
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <CardColumn>
                  <Card>
                    <LazyLoadCardImage imageSrc={card.imageSrc} />
                    <CardText>
                      <CardHeader>
                        <CardCompany>{card.company}</CardCompany>
                        <CardType>{card.type}</CardType>
                      </CardHeader>
                      <CardTitle>{card.title}</CardTitle>
                      <CardMeta>
                        <CardMetaFeature>
                          <TimeIcon /> {card.durationText}
                        </CardMetaFeature>
                        <CardMetaFeature>
                          <LocationIcon /> {card.locationText}
                        </CardMetaFeature>
                      </CardMeta>
                    </CardText>
                  </Card>
                </CardColumn>
              </SwiperSlide>
            ))}
          </Swiper>
        </PaginationStyles>
        {/* <PrimaryLink>
          <NavLink
            to="portfolio"
          >
            {linkText} <ArrowRightIcon />
          </NavLink>
        </PrimaryLink> */}
      </Content>
    </Container>
  );
};