import React, { Fragment } from "react";
import StickyHeader from "components/headers/StickyHeader";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";

const Layout = ({ children }) => {
  return (
    <Fragment>
      {/* Header fijo */}
      <StickyHeader />
      {/* Animación para las páginas */}
      <AnimationRevealPage>
        {/* Contenido dinámico de la página */}
        {children}
      </AnimationRevealPage>
      {/* Footer común en todas las páginas */}
      <Footer />
    </Fragment>
  );
};

export default Layout;
